import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import NotFound from './pages/NotFound';
import { ToastContainer } from 'react-toastify';
import 'styles/styles.global.css';
import AdSenseAds from 'components/AdSenseAds';

// Lazy load your components to reduce the initial bundle size
const BuscarCidade = lazy(() => import('./pages/BuscarCidade'));
const DadosCidade = lazy(() => import('./pages/DadosCidade'));
const RankingEstado = lazy(() => import('./pages/RankingEstado'));
const RankingPartido = lazy(() => import('./pages/RankingPartido'));
const RankingCandidato = lazy(() => import('./pages/RankingCandidato'));
const CandidatosPorPartido = lazy(() => import('./pages/CandidatosPorPartido'));
const BuscarPartido = lazy(() => import('./pages/BuscarPartido'));

// Loading component for better user experience
const LoadingSpinner = () => (
    <div style={{ textAlign: 'center', padding: '20px' }}>
        <p>Carregando...</p>
        <div className="spinner"></div> {/* Adicione um CSS para o spinner */}
    </div>
);

function App() {
    return (
        <Router>
            <Navbar />
            <AdSenseAds adSlot="6671270075"/>
            <ToastContainer />
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/buscar-cidade" element={<BuscarCidade />} />
                    <Route path="/dados-cidade/:codigoMunicipio" element={<DadosCidade />} />
                    <Route path="/ranking-estado" element={<RankingEstado />} />
                    <Route path="/ranking-partido" element={<RankingPartido />} />
                    <Route path="/ranking-candidato" element={<RankingCandidato />} />
                    <Route path="/buscar-partido" element={<BuscarPartido />} />
                    <Route path="/dados-partido/:siglaPartido" element={<CandidatosPorPartido />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
            <Footer />
        </Router>
    );
}

export default App;
