import { useState } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isActive, setIsActive] = useState(false);
    const toggleActiveClass = () => {
      setIsActive(!isActive);
    };
    const removeActive = () => {
      setIsActive(false)
    }
    return (
        <nav className={`${styles.navbar}`}>
        <Link to="/">
            <img src="/logo.png" alt="Logo" className={styles.logo} loading="lazy"/>
        </Link>
        <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
          <li onClick={removeActive}>
          <Link to="/buscar-cidade" className={`${styles.navLink}`}>Buscar município</Link>
          </li>
          <li onClick={removeActive}>
          <Link to="/buscar-partido" className={`${styles.navLink}`}>Buscar partido</Link>
          </li>
          <li onClick={removeActive}>
          <Link to="/ranking-estado" className={`${styles.navLink}`}>Ranking dos municípios</Link>
          </li>
          <li onClick={removeActive}>
          <Link to="/ranking-candidato" className={`${styles.navLink}`}>Ranking de todos os candidatos</Link>
          </li>
          <li onClick={removeActive}>
          <Link to="/ranking-partido" className={`${styles.navLink}`}>Ranking dos partidos</Link>
          </li>
        </ul>
        <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
          <span className={`${styles.bar}`}></span>
          <span className={`${styles.bar}`}></span>
          <span className={`${styles.bar}`}></span>
        </div>
      </nav>
    );
  }
  export default Navbar;
  ;