import React, { useEffect } from 'react';

interface AdSenseAdsProps {
  adSlot: string;
  adFormat?: string;
}

const AdSenseAds: React.FC<AdSenseAdsProps> = ({ adSlot, adFormat = 'auto' }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`;
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={'ca-pub-3649962084241816'}
      data-ad-slot={adSlot}
      data-ad-format={adFormat}
    />
  );
};

export default AdSenseAds;
