import styles from './styles.module.css';

const Footer = () => (
  <footer className={styles.container}>
    <p>
      Dados retirados do <a
        href="https://divulgacandcontas.tse.jus.br/divulga/#/candidato/SUDESTE/SP/2045202024"
        target="_blank"
        rel="noreferrer"
      >
        TSE - Divulgação de Candidaturas e Contas Eleitorais
      </a>
    </p>

    <p>Última atualização em: 19/10/2024</p>
  </footer>
);

export default Footer;