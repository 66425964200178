import Seo from 'components/SEO';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

function HomePage() {
    return (
        <div className='page-container'>
            <Seo 
                title="Página Inicial - Eleições 2024" 
                description="Informações detalhadas sobre as eleições de 2024 no estado de São Paulo, com análise de gastos e investimentos de campanha."
                keywords="eleições, São Paulo, gastos eleitorais, candidatos, partidos, municípios"
            />

            <header className={styles.header}>
                <h1>Custo da campanha das eleições de 2024</h1>
                <p>Bem-vindo ao portal dedicado à transparência dos gastos eleitorais no estado de São Paulo! (em breve teremos outros estados)</p>
            </header>

            <section className={styles.highlights}>
                <h2>Destaques</h2>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <h3>Gastos por Município</h3>
                        <p>Confira quanto o seu município gastou nas campanhas eleitorais.</p>
                        <Link to="/buscar-cidade" className={styles.linkButton}>Explorar Cidades</Link>
                    </div>

                    <div className={styles.card}>
                        <h3>Ranking de Municípios</h3>
                        <p>Confira a classificação dos municípios com os maiores gastos eleitorais.</p>
                        <Link to="/ranking-estado" className={styles.linkButton}>Ver Ranking</Link>
                    </div>

                    <div className={styles.card}>
                        <h3>Ranking de Candidatos</h3>
                        <p>Confira o ranking de gastos de todos os candidatos.</p>
                        <Link to="/ranking-candidato" className={styles.linkButton}>Ver Candidatos</Link>
                    </div>
                </div>
            </section>

            <section className={styles.about}>
                <h2>Sobre o Portal</h2>
                <p>
                    Este portal foi desenvolvido para promover a transparência nas eleições de 2024, disponibilizando dados claros e objetivos sobre os gastos de campanha. 
                    Nosso objetivo é informar eleitores, jornalistas e pesquisadores de forma acessível e prática, com base nas informações fornecidas pelo TSE através da Divulgação de Candidaturas e Contas Eleitorais.
                </p>
                <p>
                    Vale destacar que este portal é independente e não possui nenhum objetivo partidário. O foco é apenas apresentar de forma objetiva as despesas relatadas pelo TSE, sem quaisquer interpretações ou análises políticas.
                </p>
            </section>
        </div>
    );
}

export default HomePage;
