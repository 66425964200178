import Seo from 'components/SEO';
import styles from './styles.module.css';

const NotFoundPage = () => {
    return (
        <div className="page-container">
            <Seo 
                title="Página Não Encontrada - Custo da Campanha" 
                description="A página que você está procurando não está disponível." 
                keywords="404, página não encontrada, eleições, São Paulo"
            />
            <h1 className={styles.title}>404 - Página Não Encontrada</h1>
            <p className={styles.warning}>Desculpe, a página que você está procurando não existe ou foi removida.</p>
        </div>
    );
};

export default NotFoundPage;
